import { useRef } from 'react';
import { motion } from 'framer-motion';

import Hero from './Hero';
import About from './About';
import WorkApple from './WorkApple';
import Contact from './Contact';

import Bullet from './elements/Bullet';
import FadeInWhenVisible from './elements/FadeInWhenVisible';

import optimizely from './assets/optimizely.png';
import tvScientific from './assets/tvscientific.png';
import avantListings from './assets/avant-listings.gif';
import stillwater from './assets/stillwater.png';

import axios from 'axios';

axios.defaults.baseURL = '/api';

function App() {
  const contactSection = useRef(null);

  const scrollToContact = () => {
    window.scrollTo({ top: contactSection.current.offsetTop, behavior: 'smooth' });
  };

  return (
    <>
      <Hero handleScroll={scrollToContact} />
      <About />
      {/* Work */}
      <div className="w-full pt-[110px]">
        <div className="max-w-screen-2xl mx-auto overflow-hidden">
          <h2 className="text-5xl md:text-6xl text-center mb-[40px]">Selected Works</h2>
          <hr className="w-[50%] mx-auto mb-[120px] border-gray-200" />
          {/* Apple */}
          <WorkApple />
          {/* Optimizely */}
          <div className="bg-[#550055]/[0.04] flex flex-col lg:flex-row px-[10%] lg:px-[5%] xl:px-0 py-[80px]">
            <div className="flex flex-col justify-center lg:w-1/2 px-[5%]">
              <h2 className="text-4xl md:text-5xl mb-[40px]">Optimizely</h2>
              <div className="bg-white mb-[40px] px-[32px] py-[26px] border border-[#550055]/[0.075] rounded-md shadow-lg shadow-[#550055]/[0.05]">
                <ul className="text-lg md:text-xl">
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Maintained and enhanced the high-traffic public-facing site serving millions of monthly&nbsp;visitors</li>
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Built scalable React/TypeScript components and modernized legacy styles from SCSS to&nbsp;TailwindCSS</li>
                  <li className="ml-[15px]"><Bullet/> Collaborated with designers, backend developers, and content specialists to optimize UX and&nbsp;performance</li>
                </ul>
              </div>
              <p className="text-lg">I helped improve and maintain Optimizely's public-facing site, which serves millions of users, by developing scalable components, applying modern front end best practices, and collaborating closely with cross-functional&nbsp;teams.</p>
            </div>
            <div className="flex items-center justify-center lg:w-1/2 py-[60px]">
              <FadeInWhenVisible>
                <img className="h-auto max-h-[400px] shadow-lg" src={optimizely} alt="Optimizely" />
              </FadeInWhenVisible>
            </div>
          </div>
          {/* tvScientific */}
          <div className="flex flex-col-reverse lg:flex-row px-[10%] lg:px-[5%] xl:px-0 py-[80px]">
            <div className="flex items-center justify-center lg:w-1/2 py-[60px]">
              <FadeInWhenVisible>
                <img className="h-auto max-h-[400px] shadow-lg" src={tvScientific} alt="tvScientific" />
              </FadeInWhenVisible>
            </div>
            <div className="flex flex-col justify-center lg:w-1/2 px-[5%]">
              <h2 className="text-4xl md:text-5xl mb-[40px]">tvScientific</h2>
              <div className="bg-white mb-[40px] px-[32px] py-[26px] border border-[#550055]/[0.075] rounded-md shadow-lg shadow-[#550055]/[0.05]">
                <ul className="text-lg md:text-xl">
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Used React to build the entire platform's front end with just one other front end&nbsp;developer</li>
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Worked agilely and quickly, gracefully adapting to changing demands in a fast-moving&nbsp;environment</li>
                  <li className="ml-[15px]"><Bullet/> Collaborated with a fully remote team, with members all across the&nbsp;globe</li>
                </ul>
              </div>
              <p className="text-lg">This was my first time using React in a professional role, so I was thrilled when I was able to jump into the codebase seamlessly and start building features and pushing code on my first day.  We were working at a very fast pace, and it was extremely exciting to be part of the team that built the MVP of what is now a highly successful, rapidly-growing ad tech&nbsp;platform.</p>
            </div>
          </div>
          {/* Avant Listings */}
          <div className="bg-[#550055]/[0.04] flex flex-col lg:flex-row px-[10%] lg:px-[5%] xl:px-0 py-[80px]">
          <div className="flex flex-col justify-center lg:w-1/2 px-[5%]">
              <h2 className="text-4xl md:text-5xl mb-[40px]">Avant Listings</h2>
              <div className="mb-[40px] px-[32px] py-[26px] border border-[#550055]/[0.075] rounded-md shadow-lg shadow-[#550055]/[0.05]">
                <ul className="text-lg md:text-xl">
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Built & launched a website creator for real estate&nbsp;agents</li>
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Shared both the front & back end development with my business&nbsp;partner</li>
                  <li className="ml-[15px]"><Bullet/> Wore many other hats, including marketing, sales, and customer&nbsp;support</li>
                </ul>
              </div>
              <p className="text-lg">How do you make it so that when a user presses 'Submit', a new subdomain gets created, and then an SSL certificate is generated and placed on that subdomain, all in the split second before the user opens a new tab to view it? Those were the unique types of problems I was solving. Usually very challenging, but always highly exhilarating and endlessly&nbsp;educational.</p>
            </div>
            <div className="flex items-center justify-center lg:w-1/2 py-[60px]">
              <img className="h-auto max-h-[360px] border-4 border-black rounded-md drop-shadow-lg" src={avantListings} alt="Avant Listings" />
            </div>
          </div>
          {/* SWI */}
          <div className="flex flex-col-reverse lg:flex-row px-[10%] lg:px-[5%] xl:px-0 py-[80px]">
            <div className="flex items-center justify-center lg:w-1/2 py-[60px]">
              <FadeInWhenVisible>
                <img className="h-auto max-h-[400px]" src={stillwater} alt="Stillwater" />
              </FadeInWhenVisible>
            </div>
            <div className="flex flex-col justify-center lg:w-1/2 px-[5%]">
              <h2 className="text-4xl md:text-5xl mb-[40px]">Still Water Interactive</h2>
              <div className="bg-white mb-[40px] px-[32px] py-[26px] border border-[#550055]/[0.075] rounded-md shadow-lg shadow-[#550055]/[0.05]">
                <ul className="text-lg md:text-xl">
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Took charge of maintenance and updates for over 80 hospitality&nbsp;websites</li>
                  <li className="ml-[15px] mb-[6px]"><Bullet/> Quickly rose from the most junior developer to one of the most&nbsp;senior</li>
                  <li className="ml-[15px]"><Bullet/> Strengthened the team by helping junior developers learn NodeJS/Express</li>
                </ul>
              </div>
              <p className="text-lg">As my first dev job, the responsibility of running maintenance and ongoing updates for several dozen websites was a challenge that I was determined to make the most of. I gained tons of valuable experience in communicating with clients to bring their ideas to life, and quickly grew my development skills, both at work and through a very dedicated home study&nbsp;regimen.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      <div className="bg-[#fafafa] width-full py-[80px]">
        <div className="flex flex-col max-w-screen-2xl mx-auto">
          <h2 className="text-5xl md:text-6xl text-center px-[5%] md:px-0">What others say</h2>
          <div className="flex flex-col lg:flex-row mt-[60px] px-[10%] lg:px-0">
            <div className="lg:w-1/2 px-[5%]">
              <div className="bg-white mb-[80px] lg:mb-0 p-[7.5%] md:p-[5%] shadow-lg rounded-md">
                <p className="text-lg mb-[12px]">"I worked with James on a complex industry-specific CMS. He puts in the long hours when needed, and is able to work through complex problems in a timely fashion. James is a multi-talented team member that can not only knock out dev tasks quickly, but jump in and work in other capacities, such as marketing and/or client support. At the end of the day, he delivers functioning, quality code, on time and reliably."</p>
                <p className="text-md">Conor Macken</p>
                <p className="text-md italic">Co-founder, Avant Listings</p>
              </div>
            </div>
            <div className="lg:w-1/2 px-[5%]">
              <div className="bg-white p-[7.5%] md:p-[5%] shadow-lg rounded-md">
                <p className="text-lg mb-[12px]">"James is a model of hard work and consistency.  During his time as a developer at Still Water, we relied on his ability to learn and adapt quickly to deliver results on a variety of projects.  His technical insights and enthusiasm instantly elevated our team's capabilities.  Above that, he's a great listener and team player, willing to contribute in any role needed while bringing a positive energy to those around him. James is great to work with and would be an excellent addition to your team."</p>
                <p className="text-md">Emmanuel Briceno</p>
                <p className="text-md italic">Co-worker, Still Water Interactive</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact */}
      <Contact ref={contactSection} />
    </>
  );
}

export default App;
